<template>
  <div class="qingwu">
    <!-- 容器本身 -->
    <el-container>
      <!-- 左侧 -->
      <el-aside :width="leftBarWidth">
        <el-scrollbar>
          <!-- LOGO -->
          <div class="head_logo">
            <span>天</span><font v-show="isQingwu">熙商城</font>
          </div>
          <!-- 左侧导航 -->
          <el-menu
            background-color="#20222a"
            text-color="#e1e1e1"
            active-text-color="#fff"
            @open="handleOpen"
            @close="handleClose"
            :collapse="isCollapse"
            :router="true"
          >
            <el-menu-item index="0" route="/Admin/index">
              <i class="icon iconfont title_i">&#xe625;</i>
              <span slot="title">系统首页</span>
            </el-menu-item>

            <el-submenu
              :index="v.id + ''"
              v-for="(v, k) in permisssion_menus"
              :key="k"
            >
              <template slot="title">
                <i class="icon iconfont title_i" v-html="v.icon"></i>
                <span slot="title">{{ v.name }}</span>
              </template>
              <el-menu-item-group v-if="v.children.length > 0">
                <el-menu-item
                  :route="vo.url"
                  v-for="(vo, key) in v.children"
                  :key="key"
                  :index="vo.id + ''"
                  >{{ vo.name }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header height="102px">
          <div class="index_top_bg">
            <div class="index_header">
              <i class="icon iconfont right_head_i" @click="left_bar()"
                >&#xe62c;</i
              >
              <i
                class="icon iconfont right_head_i"
                title="刷新页面"
                @click="$router.go(0)"
                >&#xe638;</i
              >
            </div>

            <div class="head_user">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ user_info.nickname
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu class="head_menu" slot="dropdown">
                  <el-dropdown-item>XX信息科技</el-dropdown-item>
                  <el-dropdown-item command="logout" divided
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="avatar">
              <img
                :src="
                  user_info.avatar ||
                  'https://www.layui.com/admin/pro/dist/style/res/template/portrait.png'
                "
              />
            </div>

            <div class="right_head_other">
              <el-tooltip
                class="cache_flush"
                effect="dark"
                content="点击清空缓存"
                placement="bottom-start"
              >
                <i class="icon iconfont right_head_i" @click="cache_flush"
                  >&#xe631;</i
                >
              </el-tooltip>
              <el-badge is-dot :value="12" class="item" id="dot">
                <i class="icon iconfont right_head_i">&#xe793;</i>
              </el-badge>
            </div>
          </div>
          <breadcrumb-nav></breadcrumb-nav>
        </el-header>
        <el-main class="main_in">
          <el-scrollbar>
            <div class="main_in2">
              <transition name="el-fade-in-linear" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
      <el-backtop
        target=".main_in .el-scrollbar .el-scrollbar__wrap"
      ></el-backtop>
    </el-container>
  </div>
</template>

<script>
import BreadcrumbNav from "@/components/admin/BreadcrumbNav.vue";
export default {
  components: {
    BreadcrumbNav,
  },
  data() {
    return {
      isCollapse: false, // 侧边栏缩进打开
      leftBarWidth: "185px", // 左侧宽度
      isQingwu: true, // 显示Qingwu 全部
      permisssion_menus: [],
      user_info: {},
    };
  },
  methods: {
    handleOpen() {},
    handleClose() {},
    handleCommand(e) {
      // 点击退出
      if (e == "logout") {
        this.logout();
      }
    },
    left_bar() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        this.leftBarWidth = "54px";
        this.isQingwu = false;
      } else {
        this.leftBarWidth = "185px";
        this.isQingwu = true;
      }
    },
    get_user_info() {
      this.$get(this.$api.getUserInfo).then((res) => {
        this.user_info = res.data;
      });
    },
    get_permission_menus() {
      this.$get(this.$api.getPermissionMenus).then((res) => {
        this.permisssion_menus = res.data;
      });
    },
    logout() {
      this.$get(this.$api.logout).then(() => {
        localStorage.removeItem("token");
        this.$router.push("/Admin/login");
      });
    },
    cache_flush() {
      this.$get(this.$api.cacheFlush).then(() => {
        this.$message.success("清空完成");
      });
    },
  },
  created() {
    this.get_permission_menus();
    this.get_user_info();
  },
};
</script>
<style lang="scss" scoped>
.el-main {
  padding: 0;
}
.main_in {
  background: #f3f3f4;
  width: 100%;
  box-sizing: border-box;
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
  .main_in2 {
    padding: 25px;
  }
}
.el-menu {
  border-right: none;
  height: 100%;
  .el-submenu__title i {
    color: #e1e1e1;
  }
  .title_i {
    color: #cfcfcf;
    padding-right: 15px;
  }
}
.el-scrollbar {
  height: 100%;
}
.el-aside {
  height: 100%;
  overflow: hidden !important;
}
.el-container {
  height: 100%;
}
.el-header {
  padding: 0;
}

.head_logo {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  background: #000;
  color: #fff;
  span {
    color: #409eff;
  }
}
.index_top_bg {
  border-bottom: 1px solid #e7eaec !important;
  height: 50px;
  .index_header {
    float: left;
    .right_head_i {
      line-height: 50px;
      font-size: 18px;
      color: #333;
      margin-left: 30px;
    }
  }
  .avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    float: right;
    margin-top: 10px;
  }
  .right_head_other {
    float: right;
    margin-right: 40px;
    position: relative;
    .item {
      margin-top: 17px;
    }
    i {
      font-size: 20px;
    }
  }
  .head_user {
    line-height: 50px;
    float: right;
    position: relative;
    margin-left: 10px;
    margin-right: 30px;
  }
}
.cache_flush {
  line-height: 50px;
  float: left;
  margin-top: 2px;
  margin-right: 20px;
}
</style>
